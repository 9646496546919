
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const NerariumPage = props => {
	return (
		<CaseStudyPage title="Brand &amp; Branding, Comunicazione - Nerarium"
		relPath="nerarium" description="Progettazione e sviluppo di Brand e Branding. Emozionare con oggetti di Design. ">
		</CaseStudyPage>
	);
};

export default NerariumPage;
